import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeCompanyProfileParams, GetCompanyProfileDevices, GetCompanyRoleInfo, InitUserDevices, UpdateCompanyUserDevices } from '../_actions/company-profile.actions';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { ActionListItemEnum } from '../../../app-shared-elements/_enums/action-list-item.enum';
import { CompanyProfileDevices } from '../../_interfaces/company-profile.interface';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { Params } from '../../../app-shared-elements/_interfaces/params.interface';
import { initialFilterCheckboxesData } from '../../../device-dashboard/_data/user-device-params';
import { DeviceService } from '../../../device-dashboard/_services/device.service';
import { DetailsDeviceRoleInfoInterface } from '../../../details-device-container/_interface/details-device-role-info.interface';
import { PermissionsState } from '../../../app-shared-elements/_store/states/permissions.state';
import { RolesService } from '../../../admin/roles/_services/roles.service';

export interface CompanyProfileStateModel {
    userDevices: CompanyProfileDevices[];
    devices: Device[];
    params: Params;
    companyRoleInfoRow: DetailsDeviceRoleInfoInterface[];
}

const COMPANY_PROFILE_STATE_TOKEN = new StateToken<CompanyProfileStateModel>('CompanyProfileState');

const initialParams: Params = {
    pagination: null,
    sorted: null,
    filter: initialFilterCheckboxesData,
};

@State<CompanyProfileStateModel>({
    name: COMPANY_PROFILE_STATE_TOKEN,
    defaults: {
        userDevices: [],
        devices: [],
        params: initialParams,
        companyRoleInfoRow: [],
    },
})
@Injectable()
export class CompanyProfileState {
    constructor(
        private store: Store,
        private http: HttpClient,
        private deviceService: DeviceService,
        private rolesService: RolesService,
    ) {}

    @Selector()
    static getUserDevices(state: CompanyProfileStateModel): CompanyProfileDevices[] {
        console.log(state.userDevices);
        return state.userDevices;
    }

    @Selector()
    static getParams(state: CompanyProfileStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getRoleInfo(state: CompanyProfileStateModel): DetailsDeviceRoleInfoInterface[] {
        return state.companyRoleInfoRow;
    }

    @Action(InitUserDevices)
    initUserDevices(ctx: StateContext<CompanyProfileStateModel>): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            userDevices: state.devices
                .filter((device) => device.type === DeviceTypeEnum.registrator)
                .map((registrator, index) => {
                    return {
                        registrator: {
                            id: registrator.id,
                            login: registrator.login,
                            type: registrator.type,
                            lastActive: registrator.lastActive,
                            registratorType: registrator.registratorType,
                            defaultName: registrator.defaultName,
                            name: registrator.name,
                            creationType: registrator.creationType,
                            isActive: registrator.isActive,
                            isConnect: registrator.isConnect,
                            roleName: registrator.roleName,
                            roleId: registrator.roleId,
                            sleepStatus: registrator.sleepStatus,
                            isVisible: true,
                        },
                        devices: state.devices
                            .filter((d) => d.registratorId === registrator.id)
                            .map((d, i) => {
                                return {
                                    id: d.id,
                                    login: d.login,
                                    type: d.type,
                                    lastActive: d.lastActive,
                                    registratorType: d.registratorType,
                                    defaultName: d.defaultName,
                                    creationType: d.creationType,
                                    registratorId: d.registratorId,
                                    name: d.name,
                                    isVisible: true,
                                    sleepStatus: d.sleepStatus,
                                    isActive: d.isActive,
                                    isConnect: d.isConnect,
                                    listAction: [
                                        {
                                            title: 'btns.access',
                                            action: ActionListItemEnum.access,
                                        },
                                    ],
                                };
                            }),
                        isActionRegistrator: false,
                        listAction: [
                            {
                                title: 'btns.access',
                                action: ActionListItemEnum.access,
                            },
                        ],
                    };
                }),
        });
    }

    @Action(UpdateCompanyUserDevices)
    updateCompanyUserDevices(ctx: StateContext<CompanyProfileStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userDevices: state.userDevices.map((item, index) => {
                return {
                    ...item,
                    registrator: {
                        ...item.registrator,
                        isVisible: this.deviceService.getIsVisibleDevice(item.registrator, state.params),
                    },
                    devices: item.devices.map((d, i) => {
                        return {
                            ...d,
                            isVisible: this.deviceService.getIsVisibleDevice(d, state.params),
                        };
                    }),
                };
            }),
        });
    }

    @Action(GetCompanyProfileDevices)
    async getCompanyProfileDevices(ctx: StateContext<CompanyProfileStateModel>, payload: GetCompanyProfileDevices): Promise<void> {
        const result: ApiResponse = (await this.http
            .get(`/api/devices/devices-company?userId=${payload.userId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            console.log(result.data);
            ctx.setState({
                ...state,
                devices: result.data,
            });

            await ctx.dispatch(new InitUserDevices()).toPromise();
        }
    }

    @Action(ChangeCompanyProfileParams)
    setDevicesParams(ctx: StateContext<CompanyProfileStateModel>, payload: ChangeCompanyProfileParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        // const devices = this.store.selectSnapshot(DevicesState.getDevices);
        // this.store.dispatch(new UpdateUserDevicesArray(devices));
    }

    @Action(GetCompanyRoleInfo)
    getCompanyRoleInfo(ctx: StateContext<CompanyProfileStateModel>, payload: GetCompanyRoleInfo): void {
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const companyRoleInfoRow: DetailsDeviceRoleInfoInterface[] = [];
        const state = ctx.getState();

        roles
            .filter((f) => f.id === payload.roleId)
            .map(async (role) => {
                companyRoleInfoRow.push({
                    role: role.name,
                    description: `<span class="permissions">${await this.rolesService.getDescriptionForCurrentRole(role.permissions)}</span>`,
                });
            });

        ctx.setState({
            ...state,
            companyRoleInfoRow,
        });
    }
}
