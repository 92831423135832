<div class="login">
    <div class="login__wrapper">
        <ng-container *ngIf="!success">
            <h2 class="login__title" [translate]="'auth.registration.title'"></h2>
            <form class="login__form" [formGroup]="form" novalidate>
                <div class="login__form-item">
                    <label class="login__form-label" [translate]="'auth.registration.name'"></label>
                    <input class="login__form-input" placeholder="{{'auth.registration.namePlaceholder' | translate}}"
                           type="text" name="login" formControlName="name">
                </div>
                <div class="login__form-item">
                    <label class="login__form-label" [translate]="'auth.registration.login'"></label>
                    <input class="login__form-input" [ngModel]="modelEmail" (input)="isEmptyForm = false"
                           placeholder="{{'auth.registration.email' | translate}}" type="text" name="login"
                           formControlName="email">
                    <p class="login__form-error"
                       *ngIf="form.controls['email'].invalid && form.controls['email'].touched || alreadyExist"
                       [translate]="'auth.registration.errorLogin'"></p>

                </div>
                <ng-container *ngFor="let item of formArray">

                    <ng-container *ngIf="item.name === formTypeEnum.password">

                        <div class="login__form-item">
                            <label class="login__form-label" [translate]="item.title"></label>
                            <input
                                [type]="item.isVisible ? 'text' : 'password'"
                                [class.login__form-input--middle]="strength === 1 && form.value['password'] !== form.value['email']"
                                [class.login__form-input--strong]="strength > 1 && form.value['password'] !== form.value['email']"
                                (keydown.backspace)="lookChangePasswordInput()"
                                [ngModel]="modelPassword"
                                [appInputValidate]="item.validateName"
                                class="login__form-input"
                                [placeholder]="item.placeholder | translate"
                                (input)="isEmptyForm = false"
                                [formControlName]="item.name">
                            <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                                <svg-icon
                                    [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                            </div>
                            <p *ngIf="form.value['password'] !== form.value['email']"
                               class="login__form-error"
                               [class.login__form-error--middle]="strength === 1"
                               [class.login__form-error--default]="strength === -1"
                               [class.login__form-error--error]="strength === 0
                               || (strength === -1 && form.controls['password'].invalid && form.controls['password'].touched)"
                               [class.login__form-error--success]="strength > 1">
                                <span *ngIf="strength < 1" [translate]="'auth.registration.passwordNote'"></span>
                                <span *ngIf="strength === 1" [translate]="'auth.registration.passwordBetter'"></span>
                                <span *ngIf="strength === 2" [translate]="'auth.registration.passwordBest'"></span>
                            </p>

                            <p *ngIf="form.value['password'] === form.value['email'] && form.controls['password'].touched"
                               class="login__form-error" [translate]="'auth.registration.repeatPassword'"></p>

                        </div>

                    </ng-container>


                    <ng-container *ngIf="item.name === formTypeEnum.repeat">

                        <div class="login__form-item">
                            <label class="login__form-label" [translate]="item.title"></label>
                            <input class="login__form-input"
                                   [placeholder]="item.placeholder | translate"
                                   [formControlName]="item.name"
                                   [type]="item.isVisible ? 'text' : 'password'"
                                   [appInputValidate]="item.validateName"
                                   (input)="isEmptyForm = false">
                            <div class="login__icon-eyes" (click)="item.isVisible = !item.isVisible">
                                <svg-icon
                                    [src]="!item.isVisible ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                            </div>
                            <p *ngIf="form.controls['repeat'].invalid && form.controls['repeat'].touched && !isEmptyForm"
                               class="login__form-error"
                               [translate]="'auth.registration.errorRepeatPassword'"></p>
                            <p *ngIf="isEmptyForm" class="login__form-error"
                               [translate]="'auth.registration.requireError'"></p>

                        </div>

                    </ng-container>

                </ng-container>

                <div class="login__form-activity">
                    <div class="login__form-activity-item">
                        <div class="login__form-activity-column">
                            <button type="button"
                                    [translate]="'btns.registration'"
                                    [disabled]="!form.controls['email'].valid || !form.controls['repeat'].valid || form.value['email'] === form.value['password']"
                                    [accessKey]="'enter'"
                                    class="login__form-sign-btn"
                                    (click)="signUp()"></button>
                        </div>
                        <div *ngIf="(configurationServer$ | async)?.isGoogleAuthorization"
                             class="login__form-activity-column">
                            <p class="login__form-sign-with-title" [translate]="'auth.registration.with'"></p>
                            <div class="login__form-sign-with-link" (click)="googleRegister()">
                                <svg-icon [src]="GOOGLE_LOGIN_ICON_PATH"></svg-icon>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
            <div class="login__move login__move--login">
                <span [translate]="'auth.registration.question'"></span>
                <span (click)="moveLogin()" [translate]="'auth.registration.signin'"></span>
            </div>
        </ng-container>
        <ng-container *ngIf="success">
            <h2 class="login__title" [translate]="successTitle"></h2>
            <div class="login__message" [translate]="successMessage"></div>
            <button (click)="moveLogin()" class="login__form-sign-btn" [translate]="'btns.ok'"></button>
        </ng-container>

    </div>
</div>
